<script setup lang="ts">
import HeaderLogo from '~/assets/img/ys_footer_logo.svg'; // Импорт логотипа для хедера
//import FooterLogo from '~/assets/img/ys_footer_logo.svg';

const userStore = useUserStore();
</script>

<template>
    <header class="container" :class="{
        'hidden md:block': userStore.isAuthenticated
    }">
        <div class="flex justify-between ys-header">
            <nav class="flex items-center space-x-4 xl:space-x-5">
                <NuxtLink to="/" class="cursor-pointer rtl:ml-4">
                    <HeaderLogo class="flex-none ys-logo" />
                </NuxtLink>

                 <template v-if="userStore.isAuthenticated">
                    <!-- Навигационные элементы для пользователей с ролью 'CUSTOMER' -->
                    <template v-if="userStore.user?.user_role === 'CUSTOMER'">
                        <nuxt-link :to="{ name: 'customer_office' }" class="ml-3 sm:ml-0 !p-1 sm:p-3 ys-header-nav-button  items-center hidden md:flex">
                            <Icon size="20" name="ri:file-list-line" />
                            <p>{{ $t("My orders") }}</p>
                        </nuxt-link>
                        <nuxt-link :to="{ name: 'customer_office_chats' }" class="!p-1 sm:p-3 ys-header-nav-button items-center !ml-0 md:!ml-5 hidden md:flex">
                            <Icon size="20" name="ri:chat-3-line" />
                            <p class="hidden md:block">{{ $t("Chats") }}</p>
                        </nuxt-link>
                    </template>

                    <!-- Навигационные элементы для пользователей с ролью 'MASTER' -->
                    <template v-if="userStore.user?.user_role === 'MASTER'">
                        <nuxt-link :to="{ name: 'master_office' }" class="ml-3 sm:ml-0 !p-1 sm:p-3 ys-header-nav-button items-center hidden md:flex">
                            <Icon size="20" name="ri:dashboard-line" />
                            <p class="hidden md:block">{{ $t("My tasks") }}</p>
                        </nuxt-link>
                        <nuxt-link :to="{ name: 'master_office_chats' }" class="!p-1 sm:p-3 !ml-0 md:!ml-5 ys-header-nav-button items-center hidden md:flex">
                            <Icon size="20" name="ri:chat-3-line" />
                            <p class="hidden md:block">{{ $t("Chats") }}</p>
                            <!-- Отображение количества новых сообщений -->
                            <span class="new-messages-icon" v-if="userStore.unread_messages_count">{{ userStore.unread_messages_count }}</span>
                        </nuxt-link>
                    </template>
                 </template>

                 <!-- Кнопка с указанием текущего местоположения (например, Дубай), доступная неавторизованным пользователям -->
<!--                 <template v-else>
                    <CustomUiButtonLocationSelect btn_class="max-h-[36px] px-2 text-sub hover:text-main" />
                 </template>-->
            </nav>
            <!-- Второй навигационный блок -->
            <nav class="flex space-x-2 xl:space-x-4 items-center">

                <template v-if="userStore.isAuthenticated">
                    <!-- Ссылка для создания заказа для пользователей с ролью 'CUSTOMER' -->
                    <nuxt-link v-if="userStore.user?.user_role === 'CUSTOMER'" :to="{ name: 'order_create' }"
                        class="ys-header-nav-button items-center hidden md:flex">
                        <Icon size="18" name="ri:sticky-note-line" />
                        <p class="hidden md:block">{{ $t("Create Order") }}</p>
                    </nuxt-link>

                    <!-- Ссылка для поиска задач для пользователей с ролью 'MASTER' -->
                    <nuxt-link v-if="userStore.user?.user_role === 'MASTER'"
                        :to="{ name: 'master-office-task-search' }" class="ys-header-nav-button items-center hidden md:flex">
                        <Icon size="18" name="ri:search-2-line" />
                        <p class="hidden md:block">{{ $t("Tasks search") }}</p>
                    </nuxt-link>

                    <!-- Меню пользователя для авторизованных пользователей -->
                    <LayoutsUserProfileMenuBar class="rtl:!mr-4 rtl:!ml-0" />
                </template>

                <template v-else>
                    <!-- <LayoutsLanguageSelector class="rtl:ml-4" /> -->

                    <!-- Кнопка для авторизации для неавторизованных пользователей -->
                    <LayoutsAuthMainElement :icon="true" class="ltr:ml-2 rtl:!ml-0" />
                </template>
                <!-- Выбор языка для неавторизованных пользователей -->
                

                <!-- Кнопка для создания заказа для неавторизованных пользователей -->
                <!-- <LayoutsAuthMainElement
            :startIcon="'ri:sticky-note-line'"
            :icon="false" :className="'ys-header-nav-button flex items-center my-auto'"
            :title="$t('Create Order')"
            class="" v-if="!userStore.isAuthenticated"/> -->
            </nav>
        </div>
    </header>
</template>

<style scoped lang="scss">
.ys-header-nav-button {
    @apply my-auto cursor-pointer;
    color: rgba(82, 88, 102, 1);
    background: unset;
    text-decoration: unset;
    border-radius: 8px;
    font-size: 14px;
    height: 36px;
    padding: 8px 12px 8px 12px;
    gap: 8px;

    &:hover {
        background: rgba(246, 248, 250, 1);
    }
}
</style>